<template>
    <div class="login-and-register">
        <AuthorizationForms
            class="authorization-forms"
            :form-id="currentFormId"
            :initial-email="initialEmail"
            v-on="authorizationFormsListeners"
        />
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

import { AUTHORIZATION_FORMS_IDS } from '@types/CustomerModal';
import {
    LOGIN_PAGE_CONTINUE_WITH_FACEBOOK_CLICK,
    LOGIN_PAGE_CONTINUE_WITH_GOOGLE_CLICK,
    LOGIN_PAGE_SIGN_IN_EMAIL_CLICK,
    USER_LOGIN_SUCCESS,
    RESET_PASSWORD_PAGE_RESET_PASSWORD_SENT,
    RESET_PASSWORD_PAGE_RESET_PASSWORD_CLICK,
    LOGIN_PAGE_REGISTER_CLICK,
    REGISTER_PAGE_LOGIN_CLICK,
    REGISTER_PAGE_REGISTER_CLICK,
    REGISTER_PAGE_CONTINUE_WITH_FACEBOOK_CLICK,
    REGISTER_PAGE_CONTINUE_WITH_GOOGLE_CLICK,
    FORGOT_PASSWORD_PAGE_MAGIC_LINK_CLICK,
    FORGOT_PASSWORD_PAGE_MAGIC_LINK_SENT,
    PASSWORD_RECOVERY_INFO_PAGE_CHECK_EMAIL_VIEW,
    PASSWORD_RECOVERY_INFO_PAGE_BACK_TO_HOME_CLICK,
    PASSWORD_RECOVERY_INFO_PAGE_MISSING_MAGIC_LINK_CLICK,
    MISSING_MAGIC_LINK_PAGE_VIEW,
    LOGIN_PAGE_FORGOT_PASSWORD_CLICK,
} from '@analytics-types/Events';
import { MODULE_NAME as CLIENT_MODULE_NAME } from '@analytics-modules/client/meta';
import { MISSING_MAGIC_LINK_MODAL_BACK_TO_LOGIN_CLICK } from '@analytics-modules/client/types/Events';

import { EMAIL_PROVIDER, FACEBOOK_PROVIDER, GOOGLE_PROVIDER } from '@configs/auth-providers';

import AnalyticsEvents from '@mixins/AnalyticsEvents';

import AuthorizationForms from '@organisms/AuthorizationForms/AuthorizationForms';

import { JOIN_MODIVO_CLUB_PAGE_NAME } from '@router/names';

const { mapActions: mapCustomerActions } = createNamespacedHelpers('customer');

export default {
    name: 'LoginAndRegisterView',

    components: {
        AuthorizationForms,
    },

    mixins: [AnalyticsEvents],

    props: {
        currentFormId: {
            type: String,
            required: true,
            validator: value => Object.values(AUTHORIZATION_FORMS_IDS).includes(value),
        },

        initialEmail: {
            type: String,
            default: '',
        },
    },

    computed: {
        authorizationFormsListeners() {
            return {
                'set-form-id': $event => this.navigateToFormId($event),
                'login-success': $event => this.onLoginSuccess($event),
                'register-success': $event => this.onRegisterSuccess($event),
                'reset-success': this.onResetSuccess,
                'click-reset-password-button': () =>
                    this.handleClickEvent(RESET_PASSWORD_PAGE_RESET_PASSWORD_CLICK),
                'click-send-magic-link-button': () =>
                    this.handleClickEvent(FORGOT_PASSWORD_PAGE_MAGIC_LINK_CLICK),
                'click-register-button': $event => this.emitGARegisterEventClick($event),
                'click-login-button': $event => this.emitGALoginEventClick($event),
                'magic-link-sent': () =>
                    this.handleClickEvent(FORGOT_PASSWORD_PAGE_MAGIC_LINK_SENT),
                'magic-link-show-sent-information': () =>
                    this.$analytics.emit(PASSWORD_RECOVERY_INFO_PAGE_CHECK_EMAIL_VIEW),
                'magic-link-go-to-home': () =>
                    this.handleClickEvent(PASSWORD_RECOVERY_INFO_PAGE_BACK_TO_HOME_CLICK),
                'magic-link-show-missing-link-information': () =>
                    this.$analytics.emit(MISSING_MAGIC_LINK_PAGE_VIEW),
                'magic-link-back-to-login': () => {
                    this.$analytics.moduleEmit(
                        CLIENT_MODULE_NAME,
                        MISSING_MAGIC_LINK_MODAL_BACK_TO_LOGIN_CLICK
                    );
                    this.navigateToFormId(AUTHORIZATION_FORMS_IDS.LOGIN, false);
                },
                'magic-link-missing-link-click': () =>
                    this.handleClickEvent(PASSWORD_RECOVERY_INFO_PAGE_MISSING_MAGIC_LINK_CLICK),
            };
        },
    },

    methods: {
        ...mapCustomerActions(['login', 'setShouldOpenModalLoyaltyClubRegistrationFlag']),

        navigateToFormId(formId, withGaEvent = true) {
            if (withGaEvent) {
                const { LOGIN, REGISTER, FORGOT_PASSWORD } = AUTHORIZATION_FORMS_IDS;

                const EVENTS = {
                    [LOGIN]: REGISTER_PAGE_LOGIN_CLICK,
                    [REGISTER]: LOGIN_PAGE_REGISTER_CLICK,
                    [FORGOT_PASSWORD]: LOGIN_PAGE_FORGOT_PASSWORD_CLICK,
                };

                this.handleClickEvent(EVENTS[formId]);
            }

            this.$emit('navigate-to-form-id', { formId });
        },

        redirectToModivoClub() {
            this.$router.push({ name: JOIN_MODIVO_CLUB_PAGE_NAME });
        },

        onLoginSuccess({ type, isRegistration = false }) {
            this.$emit('login-success', {
                type,
                isRegistration,
            });
        },

        async onRegisterSuccess({
            registerData,
            registerToModivoClub,
            registerToLoyaltyClub,
            type,
        }) {
            const { login } = this;
            const { email, password } = registerData;

            const success = await login({ email, password });

            if (!success) {
                return;
            }

            this.$analytics.emit(USER_LOGIN_SUCCESS, {
                email,
                type,
            });

            if (registerToModivoClub) {
                return this.redirectToModivoClub();
            }

            this.onLoginSuccess({ type, isRegistration: true });

            if (registerToLoyaltyClub) {
                this.setShouldOpenModalLoyaltyClubRegistrationFlag(true);
            }
        },

        emitGALoginEventClick(loginProvider) {
            const EVENTS = {
                [EMAIL_PROVIDER]: LOGIN_PAGE_SIGN_IN_EMAIL_CLICK,
                [FACEBOOK_PROVIDER]: LOGIN_PAGE_CONTINUE_WITH_FACEBOOK_CLICK,
                [GOOGLE_PROVIDER]: LOGIN_PAGE_CONTINUE_WITH_GOOGLE_CLICK,
            };

            this.handleClickEvent(EVENTS[loginProvider]);
        },

        onResetSuccess() {
            this.$analytics.emit(RESET_PASSWORD_PAGE_RESET_PASSWORD_SENT);

            this.navigateToFormId(AUTHORIZATION_FORMS_IDS.LOGIN, false);
        },

        emitGARegisterEventClick(provider) {
            const EVENTS = {
                [EMAIL_PROVIDER]: REGISTER_PAGE_REGISTER_CLICK,
                [GOOGLE_PROVIDER]: REGISTER_PAGE_CONTINUE_WITH_GOOGLE_CLICK,
                [FACEBOOK_PROVIDER]: REGISTER_PAGE_CONTINUE_WITH_FACEBOOK_CLICK,
            };

            this.handleClickEvent(EVENTS[provider]);
        },
    },
};
</script>

<style scoped lang="scss">
.login-and-register {
    @apply mx-ui-auto;
}

@screen lg {
    .login-and-register {
        @apply mt-ui-7 mb-ui-7;

        max-width: theme('customVariables.loginAndRegister.authorizationFormsMaxWidth');

        .authorization-forms {
            @apply rounded-ds-container-medium overflow-hidden;

            &:deep(.customer-forgot-password-panel) {
                @apply px-ui-2;
            }

            &:deep(.forms) {
                @apply px-ui-2;
            }

            &:deep(.infinite-slider) {
                @apply hidden;
            }
        }

        .forms-wrapper {
            @apply mx-ui-0;
        }
    }
}
</style>
